<template>
  <div class="translate">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="init"
      v-if="list.length > 0"
    >
      <div class="cont" v-for="(g, i) in list" :key="i">
        <div class="title bgc_ffffff" v-html="g.Title"></div>
        <div class="ck_answer bgc_ffffff" v-if="show">
          <div class="title">【参考答案】</div>
          <div v-html="g.Answer" class="questionAnswer"></div>
        </div>
        <!-- <div class="fy bgc_ffffff" v-if="!show">
          <div class="title">写作</div>
          <div>{{ g.Answer }}</div>
        </div> -->
        <div class="answer bgc_ffffff">
          <div class="title">【我的答案】</div>
          <div>{{ g.studentAnswer }}</div>
        </div>
      </div>
    </van-list>
    <div v-else>
      <noData></noData>
    </div>
  </div>
</template>
<script>
import {
  queryEnglishQuestion,
  englishPracticeSave,
  queryPracticeReocrd
} from "@/api/questionBank";
import noData from "@/components/noData";
export default {
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      value: "",
      pageIndex: 1,
      pageSize: 10,
      route: {},
      answer: "",
      answer2: "",
      show: false,
      question: {},
      history: false,
      list: [],
      loading: false,
      finished: false,
    };
  },
  mounted () {
    // exercises 0 习题中心 1练习记录
    // 题目类型。0 句式分析，1 翻译、2 写作   type（0,1,2）
    this.route = this.$route.query
    this.init()
  },
  methods: {
    goHistory () {
      this.$router.go(-1)
    },
    next () {
      this.init()
      this.answer = ""
      this.answer2 = ""
      this.question = {}
    },
    async handleSubmit () {
      // if (!this.answer2) {
      //   this.$message.warning("请输入主干");
      //   return false
      // }
      if (!this.answer) {
        this.$message.warning("请输入写作");
        return false
      }
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionId: this.question.Id,
        answer: this.answer,
        answer2: this.answer2,
        questionCategory: this.route.type,
      }
      // 句式分析时传，分类ID
      // if (this.route.CategroryId) parm.wordsCategoryId = this.route.CategroryId
      const res = await englishPracticeSave(parm);
      if (res.success == true) {
        this.show = true
      }
    },
    async init () {
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionCategory: this.route.type,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }
      // 句式分析时传，分类ID
      // if (this.route.CategroryId) parm.wordsCategoryId = this.route.CategroryId
      const res = this.route.exercises == 0 ?
        await queryEnglishQuestion(parm) :
        await queryPracticeReocrd(parm)
      if (res.success == true) {
        this.list = this.list.concat(res.response.data);
        this.pageIndex++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      }
    },
  }
};
</script>
<style lang="less" scoped>
.translate {
  min-height: 100vh;
  .cont {
    margin-bottom: 10px;
    .title {
      line-height: 100%;
      padding: 15px 20px;
      font-size: 15px;
      font-weight: bold;
    }
    .trunk,
    .fy,
    .answer,
    .voc {
      font-size: 15px;
      // margin-top: 10px;
      padding: 0 20px 15px;
      .title {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .voc {
      span {
      }
      span + span {
        margin-left: 20px;
      }
    }
    .ck_answer {
      font-size: 15px;
      padding: 0 20px 15px;
      .title {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.questionAnswer {
  /deep/img {
    max-width: 100%;
  }
}
</style>
