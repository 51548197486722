<template>
  <div>
    <writing></writing>
  </div>
</template>
<script>
import writing from "@/components/writingPractice.vue";
export default {
  components: {
    writing
  },
  computed: {},
  watch: {},
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {

  },
  beforeDestroy () {
  }
};
</script>
<style lang="less" scoped>
</style>
